<template>
  <div>
    <KTCodePreview v-bind:title="'Duyệt phiếu yêu cầu nhập hàng'">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-step ml-1" data-wizard-type="step">
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div
                        class="py-8 px-4 py-lg-15 px-lg-4 pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <b-row class="mb-5">
                          <!-- INFO INPUT CONTROLS -->
                          <b-col lg="6" md="6" sm="12">
                            <b-row>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                  <template>
                                    <span>Mã phiếu:</span>
                                  </template>
                                  <p class="mt-2">{{ mainModel.code }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                  <template>
                                    <span>Người tạo:</span>
                                  </template>
                                  <p class="mt-2">{{ mainModel.createdBy }}</p>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                  <template>
                                    <span>Ngày tạo:</span>
                                  </template>
                                  <p class="mt-2">{{ mainModel.createdAt }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                  <template>
                                    <span>Tổng tiền:</span>
                                  </template>
                                  <p class="mt-2">
                                    {{ formatMoney(totalPrice) }}
                                  </p>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group class="required-control">
                                  <label>Kho:</label>
                                  <vue-autosuggest
                                    class="border-radius-none mt-2"
                                    :suggestions="filteredOptions"
                                    @selected="onSelectedStore"
                                    :limit="200"
                                    @input="onInputChange"
                                    :input-props="inputPropStore"
                                    v-model="searchStore"
                                  >
                                    <div
                                      slot-scope="{ suggestion }"
                                      style="display: flex; align-items: center"
                                    >
                                      <div
                                        style="{ display: 'flex', color: 'navyblue'}"
                                      >
                                        {{ suggestion.item.suggestionName }}
                                      </div>
                                    </div>
                                  </vue-autosuggest>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group class="required-control">
                                  <label>Nhà cung cấp:</label>
                                  <vue-autosuggest
                                    class="border-radius-none mt-2"
                                    :suggestions="filteredOptionsProvider"
                                    @selected="onSelectedProvider"
                                    :limit="200"
                                    @input="onInputChangeProvider"
                                    :input-props="{
                                      class: 'autosuggest__input',
                                      placeholder: 'Chọn nhà cung cấp',
                                      style: 'border-radius:0px!important',
                                      disabled: true,
                                    }"
                                    v-model="searchProvider"
                                  >
                                    <div
                                      slot-scope="{ suggestion }"
                                      style="display: flex; align-items: center"
                                    >
                                      <div
                                        style="{ display: 'flex', color: 'navyblue'}"
                                      >
                                        {{ suggestion.item.suggestionName }}
                                      </div>
                                    </div>
                                  </vue-autosuggest>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group disabled>
                                  <label>Lý do nhập hàng:</label>
                                  <b-form-textarea
                                    size="sm"
                                    v-model="mainModel.importReason"
                                    :placeholder="'Thêm lý do nhập hàng..'"
                                    :rows="6"
                                    :max-rows="6"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group disabled>
                                  <label>Ghi chú:</label>
                                  <b-form-textarea
                                    size="sm"
                                    v-model="mainModel.description"
                                    :placeholder="'Thêm nội dung ghi chú...'"
                                    :rows="6"
                                    :max-rows="6"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- INFO VAT INPUT CONTROLS -->
                          <b-col lg="3" md="3" sm="12">
                            <b-form-group :disabled="!editable">
                              <label>Doanh nghiệp:</label>
                              <b-form-select
                                class="select-style"
                                v-model="mainModel.companyId"
                                :options="listCompany"
                                size="sm"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-group>
                              <label>Hình thức thanh toán:</label>
                              <b-form-select
                                class="select-style"
                                v-model="mainModel.importType"
                                :options="listImportType"
                                size="sm"
                                value-field="id"
                                text-field="name"
                                disabled
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-group>
                              <label>Chiết khấu:</label>
                              <b-row>
                                <b-col class="pr-0" cols="5">
                                  <b-form-select
                                    class="select-style"
                                    v-model="mainModel.discountType"
                                    :options="listAmountType"
                                    size="sm"
                                    value-field="id"
                                    text-field="name"
                                    disabled
                                  >
                                  </b-form-select>
                                </b-col>
                                <b-col class="pl-0" cols="7">
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    v-model="mainModel.discountAmount"
                                    v-mask="formater.currency"
                                    placeholder="Chiết khấu"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>

                            <b-form-group>
                              <label>VAT:</label>
                              <b-row>
                                <b-col class="pr-0" cols="5">
                                  <b-form-select
                                    class="select-style"
                                    v-model="mainModel.vatType"
                                    :options="listAmountType"
                                    size="sm"
                                    value-field="id"
                                    text-field="name"
                                    disabled
                                  >
                                  </b-form-select>
                                </b-col>
                                <b-col class="pl-0" cols="7">
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="mainModel.vatAmount"
                                    v-mask="formater.currency"
                                    placeholder="VAT"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>

                            <b-form-group>
                              <label>Số hóa đơn VAT:</label>
                              <b-form-input
                                class="input-style"
                                size="sm"
                                type="text"
                                v-model="mainModel.vatCode"
                                placeholder="Số hóa đơn VAT"
                                disabled
                              ></b-form-input>
                            </b-form-group>
                            <b-form-group :label="'Ngày xuất VAT'" disabled>
                              <date-picker
                                placeholder="Chọn ngày xuất VAT"
                                class="form-control form-control-sm"
                                :config="dpConfigs.date"
                                v-model="mainModel.vatDate"
                              ></date-picker>
                            </b-form-group>
                            <b-form-group>
                              <template>
                                <span>Người liên hệ:</span>
                              </template>
                              <p class="mt-2">
                                {{ mainModel.inChargeEmployeeName }}
                              </p>
                            </b-form-group>
                          </b-col>
                          <!-- PAYMENT INPUT CONTROLS -->
                          <b-col lg="3" md="3" sm="12">
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group disabled>
                                  <label>Tiền mặt</label>
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="mainModel.cashAmount"
                                    placeholder="Nhập số tiền mặt"
                                    v-mask="formater.currency"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group :label="'Tài khoản tiền mặt'">
                                  <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                                  <vue-autosuggest
                                    class="border-radius-none"
                                    aria-describedby="input-store-live-feedback"
                                    :suggestions="filteredOptionsCash"
                                    @selected="onSelectedCash"
                                    :limit="200"
                                    @input="onInputChangeCash"
                                    :input-props="{
                                      class: 'autosuggest__input',
                                      placeholder: 'Chọn tài khoản tiền mặt',
                                      style: 'border-radius:0px!important',
                                      disabled: true,
                                    }"
                                    v-model="searchCash"
                                  >
                                    <div
                                      slot-scope="{ suggestion }"
                                      style="display: flex; align-items: center"
                                    >
                                      <div
                                        style="{ display: 'flex', color: 'navyblue'}"
                                      >
                                        {{ suggestion.item.suggestionName }}
                                      </div>
                                    </div>
                                  </vue-autosuggest>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group disabled>
                                  <label>Tiền chuyển khoản</label>
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="mainModel.transferAmount"
                                    placeholder="Nhập số tiền"
                                    v-mask="formater.currency"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group
                                  :label="'Tài khoản chuyển khoản'"
                                  label-for="select-accountant"
                                >
                                  <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                                  <vue-autosuggest
                                    class="border-radius-none"
                                    aria-describedby="input-store-live-feedback"
                                    :suggestions="filteredOptionsTransfer"
                                    @selected="onSelectedTransfer"
                                    :limit="200"
                                    @input="onInputChangeTransfer"
                                    :input-props="{
                                      class: 'autosuggest__input',
                                      placeholder:
                                        'Chọn tài khoản chuyển khoản',
                                      style: 'border-radius:0px!important',
                                      disabled: true,
                                    }"
                                    v-model="searchTransfer"
                                  >
                                    <div
                                      slot-scope="{ suggestion }"
                                      style="display: flex; align-items: center"
                                    >
                                      <div
                                        style="{ display: 'flex', color: 'navyblue'}"
                                      >
                                        {{ suggestion.item.suggestionName }}
                                      </div>
                                    </div>
                                  </vue-autosuggest>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group
                                  :label="'Ngày thanh toán'"
                                  disabled
                                >
                                  <date-picker
                                    placeholder="Chọn ngày thanh toán"
                                    class="mb-2 form-control form-control-sm"
                                    :config="dpConfigs.date"
                                    v-model="mainModel.payDate"
                                  ></date-picker>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="8">
                            <span class="font-weight-bolder lable-size"
                              >Danh sách sản phẩm:</span
                            >
                          </b-col>
                          <b-col md="4">
                            <b-checkbox
                              v-model="mainModel.isVAT"
                              class="d-flex justify-content-end lable-size"
                              :disabled="true"
                              >Đơn giá đã bao gồm VAT
                            </b-checkbox>
                          </b-col>
                        </b-row>
                        <b-table
                          :fields="fieldProduct"
                          :items="listProductStock"
                          v-if="renderComponent"
                          hover
                          bordered
                          class="mt-4"
                        >
                          <template v-slot:cell(productName)="row">
                            <span
                              v-text="row.item.barCode"
                              style="font-size: 11px"
                              v-show="row.item.barCode"
                            ></span>
                            <br />
                            <span
                              v-text="row.item.productCode"
                              style="font-size: 12px"
                              v-show="row.item.productCode"
                            ></span>
                            <br />
                            <span
                              v-text="row.item.productName"
                              style="white-space: normal; color: #3699ff"
                            ></span>
                          </template>
                        </b-table>

                        <div class="col-md-12" style="padding-left: 0px">
                          <div class="mb-5 mt-5">
                            <b-button
                              v-bind:style="btnCreate"
                              size="sm"
                              variant="primary"
                              class="mr-4"
                              @click="onSubmit"
                            >
                              Duyệt
                            </b-button>
                            <b-button
                              v-bind:style="btnCancel"
                              size="sm"
                              variant="warning"
                              class="mr-4"
                              @click="showModalCancel"
                            >
                              Trả phiếu
                            </b-button>
                          </div>
                          <CancelPO
                            ref="po-cancel"
                            :purchaseOrderCode="mainModel.code"
                            :purchaseOrderId="mainModel.id"
                            :mode="
                              mainModel.status === PURCHASE_ORDER_STATUS.READY
                                ? PURCHASE_ORDER_APPROVE_MODE.PURCHASE
                                : PURCHASE_ORDER_APPROVE_MODE.ACCOUNTANT
                            "
                            v-on:onSuccess="onBack"
                          />
                        </div>
                      </div>

                      <div
                        class="py-4 mb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <div class="upload-file col-12">
                          <vue-easy-lightbox
                            escDisabled
                            :visible="previewFile.visible"
                            :imgs="previewFile.imgs"
                            :index="previewFile.index"
                            @hide="handleHidePreviewFile"
                          ></vue-easy-lightbox>

                          <vue-easy-lightbox
                            escDisabled
                            :visible="previewAttachment.visible"
                            :imgs="previewAttachment.imgs"
                            :index="previewAttachment.index"
                            @hide="handleHidePreviewAttachment"
                          ></vue-easy-lightbox>

                          <div>
                            <b-col
                              class="preview-img-container mb-4"
                              v-if="isLoadAttachmentSuccess"
                            >
                              <div>
                                <!--File Previews-->
                                <div class="d-flex">
                                  <div
                                    v-for="(item, index) in attachments"
                                    :key="index"
                                    class="img-container align-items-start"
                                  >
                                    <img
                                      v-if="typecheck(item.fileName)"
                                      :src="item.url"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="
                                        showMultiplePreViewAttachment(index)
                                      "
                                    />
                                    <img
                                      v-else
                                      src="../../../assets/google-docs.svg"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="
                                        showMultiplePreViewAttachment(index)
                                      "
                                    />
                                    <i
                                      class="
                                        fas
                                        fa-times-circle
                                        text-danger
                                        close
                                      "
                                      @click="deleteAttachment(item.id)"
                                    >
                                    </i>
                                    <a
                                      :download="item.fileName"
                                      :href="item.url"
                                      ><i
                                        class="
                                          fas
                                          fa-arrow-circle-down
                                          text-warning
                                          remove
                                        "
                                      >
                                      </i
                                    ></a>
                                    <!-- <p
                                        style="max-width: 100px"
                                        class="text-center"
                                      >
                                        <a
                                          :download="item.fileName"
                                          :href="item.url"
                                        ><b> {{ ftruncate(item.fileName) }}</b></a>
                                      </p> -->
                                  </div>
                                </div>
                              </div>
                            </b-col>

                            <!--UPLOAD-->
                            <form
                              enctype="multipart/form-data"
                              novalidate
                              class="mb-4"
                            >
                              <b-button
                                size="sm"
                                variant="secondary"
                                @click="openFileUpload()"
                                class="font-weight-bolder mr-2"
                              >
                                <i class="fas fa-paperclip"></i>
                                Đính kèm tệp
                              </b-button>
                              <b-button
                                size="sm"
                                variant="warning"
                                @click="submitFileUpload()"
                                class="font-weight-bolder"
                                v-if="uploadedFiles.length"
                              >
                                <i class="fas fa-upload"></i>
                                Tải tệp lên
                              </b-button>
                              <b-form-file
                                v-model="fileAttach.files"
                                ref="upload-files"
                                id="upload-files"
                                class="mb-2 d-none input-file"
                                multiple
                                :name="uploadFieldName"
                                :disabled="isSaving"
                                v-on:change="
                                  filesChange(
                                    $event.target.name,
                                    $event.target.files
                                  );
                                  fileCount = $event.target.files.length;
                                "
                              />

                              <p v-if="isSaving">
                                Đang tải {{ fileCount }} tệp...
                              </p>
                              <div v-if="isFailed" class="d-flex">
                                <small class="text-danger"
                                  >Tải tệp thất bại.</small
                                >
                                <small>
                                  <a href="javascript:void(0)" @click="reset()"
                                    >Thử lại</a
                                  >
                                </small>
                                <pre>{{ uploadError }}</pre>
                              </div>
                            </form>

                            <b-col
                              class="preview-img-container mb-4"
                              v-if="isSuccess"
                            >
                              <div>
                                <!--File Previews-->
                                <div class="d-flex">
                                  <div
                                    v-for="(item, index) in uploadedFiles"
                                    :key="index"
                                    class="img-container align-items-start"
                                  >
                                    <img
                                      v-if="typecheck(item.fileName)"
                                      :src="item.url"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="showMultiplePreViewFile(index)"
                                    />
                                    <img
                                      v-else
                                      src="../../../assets/google-docs.svg"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="showMultiplePreViewFile(index)"
                                    />

                                    <i
                                      class="
                                        fas
                                        fa-times-circle
                                        text-danger
                                        close
                                      "
                                      @click="
                                        removeFile(
                                          fileCount,
                                          uploadedFiles.indexOf(item)
                                        )
                                      "
                                    >
                                    </i>

                                    <!-- <p
                                        style="max-width: 100px"
                                        class="text-center"
                                      >
                                        <b> {{ ftruncate(item.fileName) }}</b>
                                      </p> -->
                                  </div>
                                </div>
                              </div>
                            </b-col>
                            <!--SUCCESS-->
                            <p v-if="isSuccess">
                              <b>Tổng số {{ uploadedFiles.length }} tệp</b>
                            </p>
                            <!--FAILED-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { cmdUrl } from './../../../utils/apiUrl';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import { TIME_TRIGGER } from './../../../utils/constants';
import {
  AMOUNT_TYPE,
  PURCHASE_ORDER_APPROVE_MODE,
  PURCHASE_ORDER_STATUS,
  UPLOAD_ENTITY,
} from './../../../utils/enum';
import CancelPO from '../../components/stock-slips/CancelPO';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import VueEasyLightbox from 'vue-easy-lightbox';

import axios from 'axios';
import JwtService from '@/core/services/jwt.service';

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      btnCancel: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '100px',
      },
      form: {
        dateImport: '',
      },
      isNew: true,
      listInventories: [],
      listProviders: [],
      listProductStock: [],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inputSearchProductProp: {
        class: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      isSearching: false,
      inputPropStore: {
        class: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      fieldProduct: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
        },
        {
          key: 'prevUnitPrice',
          label: 'Giá thấp nhất',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'salePowerWeek',
          label: 'Sức bán tuần',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'salePowerMonth',
          label: 'Sức bán tháng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá(VAT)',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
        },
        {
          key: 'totalAmount',
          label: 'Thành tiền',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '11%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'salePrice',
          label: 'Đơn giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '12%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
      ],
      renderComponent: true,
      mainModel: {
        id: 1,
        code: 'DEMO_123456',
        status: 1,
        providerId: null,
        description: 'Phiếu yêu cầu mua hàng từ SamSung Electronic',
        storeId: null,
        discountAmount: 0,
        discountType: 1,
        vatAmount: 0,
        vatType: 2,
        cashAmount: 0,
        cashAccountCode: '',
        transferAmount: 0,
        transferAccountCode: '',
        payDate: null,
        vatCode: '',
        vatDate: '',
        createdAt: '',
        createdBy: '',
        importType: 1,
        importReason: '',
        inChargeEmployeeName: '',
        companyId: null,
        isVAT: true,
      },
      PURCHASE_ORDER_STATUS: PURCHASE_ORDER_STATUS,
      PURCHASE_ORDER_APPROVE_MODE: PURCHASE_ORDER_APPROVE_MODE,
      listImportType: [
        {
          id: 1,
          name: 'Đặt cọc',
        },
        {
          id: 2,
          name: 'Thanh toán tiền ngay',
        },
        {
          id: 3,
          name: 'Bù trừ đơn hàng',
        },
        {
          id: 4,
          name: 'Công nợ',
        },
        {
          id: 5,
          name: 'Ký gửi',
        },
      ],
      previewFile: {
        visible: false,
        imgs: '',
        index: 0,
      },
      previewAttachment: {
        visible: false,
        imgs: '',
        index: 0,
      },
      attachments: null,
      isLoadAttachmentSuccess: false,
      fileAttach: {
        files: [],
        fileModels: [],
      },
      uploadedFiles: [],
      fileCount: 0,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      hashName: null,
      listCompany: [],
    };
  },
  validations: {
    form: {
      dateImport: { required },
    },
    searchStore: { required },
  },
  components: {
    KTCodePreview,
    datePicker,
    CancelPO,
    VueEasyLightbox,
  },
  created() {
    this.fetchStoreByUser();
    this.fetchProvider();
    this.fetchAccountants('transfer');
    this.fetchCompany();
  },
  mounted() {
    this.$v.form.dateImport.$model = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: '/purchase-orders' },
      { title: 'Duyệt phiếu yêu cầu nhập hàng' },
    ]);
    if (this.$route.query.id) {
      this.mainModel.id = this.$route.query.id;
      this.getInfoById();
    }
    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.unitPrice) * unMaskPrice(element.quantity) -
          unMaskPrice(element.discountAmount);
      }

      const discountValue = this.mainModel.discountAmount
        ? unMaskPrice(this.mainModel.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.mainModel.discountType === AMOUNT_TYPE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.mainModel.vatAmount
        ? unMaskPrice(this.mainModel.vatAmount)
        : 0;

      let vatAmount = vatValue;
      if (this.mainModel.vatType === AMOUNT_TYPE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discountAmount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSubmit: async function () {
      if (!this.mainModel.providerId) {
        this.makeToastFaile('Vui lòng chọn nhà cung cấp!');
        return;
      }

      const data = {
        id: this.mainModel.id,
        mode:
          this.mainModel.status === PURCHASE_ORDER_STATUS.READY
            ? PURCHASE_ORDER_APPROVE_MODE.PURCHASE
            : PURCHASE_ORDER_APPROVE_MODE.ACCOUNTANT,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post('purchase-order/approve', data)
          .then(({ data }) => {
            if (data.status === 1) {
              this.isNew = true;
              this.makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  name: 'list-purchase-orders',
                });
              }, TIME_TRIGGER);
            } else {
              this.isNew = true;
              this.makeToastFaile(data.message);
            }
          })
          .catch(({ response }) => {
            this.isNew = true;
            this.makeToastFaile(response.data.message);
          });
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.mainModel.storeId !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.mainModel.storeId,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        let tmpCash = [{ data: this.optionsCash[0].data }];
        this.filteredOptionsCash = tmpCash;
        let tmpTransfer = [{ data: this.optionsTransfer[0].data }];
        this.filteredOptionsTransfer = tmpTransfer;
      });
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.listProviders.map((element) => {
          let provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let tmp = [{ data: this.optionsProvider[0].data }];
        this.filteredOptionsProvider = tmp;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `productSearch?storeId=${this.mainModel.storeId}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          const nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    onChangeStore() {
      this.listProductStock = [];
      this.fetchAccountants('cash');
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.mainModel.storeId = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.mainModel.providerId = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.mainModel.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [
        {
          data: filteredData,
        },
      ];
    },
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.mainModel.storeId = stores[0].id;
            this.searchStore = stores[0].name;
            this.inputPropStore.disabled = true;
            this.inputSearchProductProp.disabled = false;
            this.fetchAccountants('cash');
          } else {
            this.options[0].data = [];
            stores.map((element) => {
              let store = {
                id: element.id,
                name: element.name,
                shortName: element.shortName,
                suggestionName: element.shortName + ' ( ' + element.name + ')',
              };
              this.options[0].data.push(store);
            });
            let tmp = [{ data: this.options[0].data }];
            this.filteredOptions = tmp;
          }
        }
      });
    },
    getInfoById() {
      ApiService.get(`purchase-order/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel.status = data.data.status
            ? parseInt(data.data.status)
            : 1;
          this.mainModel.code = data.data.code ? data.data.code : '';
          this.mainModel.description = data.data.description
            ? data.data.description
            : '';
          this.mainModel.importReason = data.data.importReason
            ? data.data.importReason
            : '';
          this.mainModel.inChargeEmployeeName = data.data.inChargeEmployeeName
            ? data.data.inChargeEmployeeName
            : '';
          this.searchStore = data.data.storeName ? data.data.storeName : '';
          this.mainModel.storeId = data.data.storeId;
          this.mainModel.companyId = data.data.companyId;
          this.searchProvider = data.data.providerName
            ? data.data.providerName
            : '';
          this.mainModel.providerId = data.data.providerId;
          this.mainModel.createdBy = data.data.createdByName
            ? data.data.createdByName
            : '';
          this.mainModel.createdAt = data.data.createdAt
            ? moment(data.data.createdAt).format('HH:mm:ss DD/MM/YYYY')
            : '';
          this.mainModel.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.mainModel.vatAmount = data.data.vatAmount
            ? data.data.vatAmount
            : 0;
          this.mainModel.isVAT = data.data.isVAT;
          this.mainModel.discountType = data.data.discountType
            ? data.data.discountType
            : 1;
          this.mainModel.vatType = data.data.vatType ? data.data.vatType : 2;
          this.mainModel.cashAmount = data.data.cashAmount
            ? data.data.cashAmount
            : 0;
          this.mainModel.cashAccountCode = data.data.cashAccountCode
            ? data.data.cashAccountCode
            : '';
          this.mainModel.transferAmount = data.data.transferAmount
            ? data.data.transferAmount
            : 0;
          this.mainModel.transferAccountCode = data.data.transferAccountCode
            ? data.data.transferAccountCode
            : '';
          this.mainModel.payDate = data.data.payDate
            ? moment(data.data.payDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.vatDate = data.data.vatDate
            ? moment(data.data.vatDate).format('DD-MM-YYYY')
            : '';
          this.searchCash = data.data.cashAccountName
            ? data.data.cashAccountName
            : '';
          this.searchTransfer = data.data.transferAccountName
            ? data.data.transferAccountName
            : '';
          this.mainModel.vatCode = data.data.vatCode ? data.data.vatCode : '';
          this.mainModel.importType = data.data.type ? data.data.type : 1;
          this.listProductStock = data.data.details.map((item) => {
            const totalAmount =
              item.quantity * item.unitPrice - item.discountAmount;
            return {
              ...item,
              totalAmount: totalAmount,
            };
          });
          this.fetchAccountants('cash');
          this.loadAttachments();
          this.onChangeVat();
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    onBack() {
      this.$router.push({
        name: 'list-purchase-orders',
      });
    },
    showModalCancel() {
      this.$refs['po-cancel'].showModal();
    },
    deleteAttachment(id) {
      ApiService.delete(`${cmdUrl.File.root}/${id}`).then((deleteResponse) => {
        if (deleteResponse.status === 200) {
          if (deleteResponse.data.data === true) {
            const index = this.attachments.findIndex((item) => item.id === id);
            this.attachments.splice(index, 1);
          }
        }
      });
    },
    loadAttachments() {
      this.isLoadAttachmentSuccess = false;
      ApiService.query('file', {
        params: {
          entity: UPLOAD_ENTITY.PO,
          entityId: this.mainModel.id,
        },
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            const attachments = resp.data.data.map((item) => ({
              id: item.id,
              fileName: item.fileName,
              originalName: item.fileName,
            }));

            this.attachments = await Promise.all(
              attachments.map(async ({ fileName, ...rest }) => {
                const resp = await axios.get(
                  `${cmdUrl.File.download}?filename=${fileName}`,
                  {
                    responseType: 'arraybuffer',
                    headers: {
                      Authorization: `Bearer ${JwtService.getToken()}`,
                    },
                  }
                );
                const contentType = resp.headers['content-type'];
                const base64 = `data:${contentType};base64,${Buffer.from(
                  resp.data
                ).toString('base64')}`;
                return {
                  ...rest,
                  url: base64,
                  fileName,
                };
              })
            );

            this.isLoadAttachmentSuccess = true;
          }
        })
        .catch(() => {
          this.isLoadAttachmentSuccess = false;
        });
    },
    handleHidePreviewFile() {
      this.previewFile.visible = false;
    },
    showMultiplePreViewFile(indexOfFile) {
      const files = this.uploadedFiles.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewFile.imgs = files;
      this.previewFile.index = indexOfFile;
      this.previewFile.visible = true;
    },
    handleHidePreviewAttachment() {
      this.previewAttachment.visible = false;
    },
    showMultiplePreViewAttachment(indexOfFile) {
      const files = this.attachments.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewAttachment.imgs = files;
      this.previewAttachment.index = indexOfFile;
      this.previewAttachment.visible = true;
    },
    openFileUpload() {
      document.getElementById('upload-files').click();
    },
    handleFileUpload(event) {
      // this.linkHref = "";
      this.fileAttach.files = event.target.files;
      // this.sendImage = false;
      // if (!this.imageInput.length) return;
      // this.createImage(this.imageInput[0]);
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    //This is where we implement the storage service
    save(formData) {
      this.currentStatus = STATUS_SAVING;
      //Implement your own storage service when upload() is called.
      //For test purposes, I added a delay service
      this.upload(formData)
        .then((x) => {
          this.uploadedFiles = this.uploadedFiles.concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          console.log(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    upload(formData) {
      const photos = formData.getAll('photos');
      const promises = photos.map((x) =>
        this.getImage(x).then((img) => ({
          id: img,
          originalName: x.name,
          fileName: x.name,
          url: img,
        }))
      );
      return Promise.all(promises);
    },
    getImage(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        const img = document.createElement('img');

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(this.getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = img.src;
      return dataURL;
    },
    //Returns true for image data types for others false, for preview purpose
    typecheck(oInput) {
      var _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
      if (oInput.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            oInput
              .substr(
                oInput.length - sCurExtension.length,
                sCurExtension.length
              )
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    //Removes the selected file from the array.
    removeFile(fileCount, key) {
      fileCount = this.uploadedFiles.length;
      if (fileCount === 1) {
        this.uploadedFiles.splice(key, 1);
        this.reset();
      } else {
        this.uploadedFiles.splice(key, 1);
      }
    },
    //Func to truncate long filenames to short ones to display
    ftruncate(n) {
      // var len = 10;
      var ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
      var filename = n.replace('.' + ext, '');
      // if (filename.length <= len) {
      //   return n;
      // }
      // filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
      return filename + '.' + ext;
    },
    // Handle file changes
    filesChange(fieldName, fileList) {
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // Save it to cloud
      this.save(formData);
    },
    submitFileUpload() {
      let formData = new FormData();
      for (const i of Object.keys(this.fileAttach.files)) {
        formData.append('fileUploads', this.fileAttach.files[i]);
      }
      this.currentStatus = STATUS_SAVING;
      const context = this;
      axios
        .post(
          `${cmdUrl.File.upload}?entityId=${this.mainModel.id}&entity=${UPLOAD_ENTITY.PO}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${JwtService.getToken()}`,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            context.currentStatus = STATUS_SUCCESS;
          }
        })
        .catch(() => {
          context.currentStatus = STATUS_FAILED;
        });
    },
    fetchCompany: async function () {
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Chọn doanh nghiệp',
        });
      });
    },
    onChangeVat() {
      const vatTableField = this.fieldProduct.find(
        (field) => field.key === 'unitPrice'
      );
      console.log('vatTableField: ', vatTableField, this.mainModel.isVAT)
      if (vatTableField) {
        vatTableField.label = this.mainModel.isVAT
          ? 'Đơn giá (VAT)'
          : 'Đơn giá';
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>

<style lang="scss">
.upload-file {
  .dropbox {
    margin: auto;
    width: 70%;
    background: #f8f8f8;
    border-radius: 20px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    left: 0px;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #e8f5e9;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  .img-container {
    position: relative;
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
  }
  .preview-img {
    max-width: 80px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #eeecff;
    border-radius: 5px;
  }
  .preview-img-container {
    border: 2px dashed #eeecff;
    padding: 2rem 1rem;
  }

  .preview-box {
    display: inline-block;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
  }
  .remove {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
  }
  .cancel {
    color: #545454;
    text-decoration: none;
  }
}
</style>
